import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private _router: Router, private route: ActivatedRoute) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(catchError((err) => this.handleAuthError(err)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //Retry to authenticate twice otherwise, redirect to the error page.

    if (err.status === 401) {
      let authErrorCounter = +localStorage.getItem('authErrorCounter');

      if (authErrorCounter > 1) {
        localStorage.removeItem('authErrorCounter');
        this._router.navigateByUrl('error', {
          state: {
            errorTitle: 'Failed to authenticate.',
            errorMessage: 'Please reach out to servicedesk@claytonhomes.com.',
          },
        });
      } else {
        authErrorCounter++;
        localStorage.clear();
        localStorage.setItem('authErrorCounter', authErrorCounter.toString());
        location.reload();
      }
    }
    throw err;
  }
}
