import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { APP_INITIALIZER, enableProdMode } from '@angular/core';
import { APOLLO_OPTIONS, Apollo } from 'apollo-angular';
import {
  ApolloClientOptions,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { GraphqlTokenInterceptor } from './app/shared/interceptors/graphql-token.interceptor';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import { environment } from './environments/environment';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalInterceptor,
  MsalGuard,
  MsalBroadcastService,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import { UrlBuilderService } from './app/shared/services/url-builder.service';
import { ThemeService } from './app/shared/services/theme.service';
import { SpinnerInterceptor } from './app/shared/interceptors/spinner.interceptor';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { AuthenticationInterceptor } from './app/shared/interceptors/authentication.interceptor';
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions,
} from '@angular/material/checkbox';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'dd5be350-ed51-4813-8a17-1801dfb9710e',
    clientToken: 'pub64f7946de898b2a511d62493cc695656',
    site: 'datadoghq.com',
    service: 'cmh.hbg.claytonnext',
    env: environment.environment,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackSessionAcrossSubdomains: true,
    allowedTracingUrls : [
      /^https:\/\/graph-api?(-?([^.]+))\.(dev|prod|qua|itg|mt1|mt2|mt3)\.claytonnext\.com\/graphql/
    ]
});

export function loggerCallback(logLevel: LogLevel, message: string) {
  if (logLevel === LogLevel.Error) {
    console.error(message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: `https://login.microsoftonline.com/${environment.tenantId}`,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function ApolloClientFactory(
  httpLink: HttpLink,
  urlBuilder: UrlBuilderService
): ApolloClientOptions<any> {
  const uri = urlBuilder.generateGraphUrl();

  return {
    link: ApolloLink.from([
      httpLink.create({
        uri,
      }),
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

export function LoadTheme(service: ThemeService) {
  return () => service.loadUserTheme();
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: LoadTheme,
      deps: [ThemeService],
      multi: true,
    },
    provideRouter(routes),
    {
      provide: APOLLO_OPTIONS,
      useFactory: ApolloClientFactory,
      deps: [HttpLink, UrlBuilderService],
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GraphqlTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2500,
        panelClass: ['snackbar-theme'],
        horizontalPosition: 'center',
        verticalPosition: 'top',
      },
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    Apollo,
  ],
});
