import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

type SortOrder = 'asc' | 'desc';

@Pipe({
  standalone: true,
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, sortBy: string, order?: string): any[] {
    const sortOrder = order ? order : 'asc'; // setting default ascending order
    const sortOrders: SortOrder[] = [];
    sortOrders.push(order === 'desc' ? 'desc' : 'asc');
    return orderBy(array, [sortBy], sortOrders);
  }
}
