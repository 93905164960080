import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavItemComponent } from './nav-item.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavItem } from '../../models';
import { NavService } from '../services/nav.service';

@Component({
  selector: 'nav-bar',
  imports: [NavItemComponent, CommonModule, MatSidenavModule],
  template: `
    <mat-sidenav-container hasBackdrop="false" class="container">
      <mat-sidenav class="navbar" mode="side" position="start" opened>
        <div id="logo-container">
          <!-- <img
            src="./assets/logos/Clayton_Next_Full_Color_Stacked_Logo_RGB.svg"
            alt=""
          /> -->

          <svg viewBox="0 0 407 266" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M369.954 210.405C379.947 210.405 388.047 202.309 388.047 192.322C388.047 182.335 379.947 174.239 369.954 174.239C359.962 174.239 351.862 182.335 351.862 192.322C351.862 202.309 359.962 210.405 369.954 210.405Z"
            />
            <path
              d="M363.188 185.368L368.395 180.774L380.273 193.116L368.013 205.098L363.188 200.43L370.969 193.108L363.188 185.368Z"
              fill="#171717"
            />
            <path
              d="M231.687 208.097V190.8C231.687 189.911 231.613 189.065 231.466 188.257C231.319 187.456 231.076 186.742 230.731 186.125C230.392 185.515 229.914 185.022 229.296 184.662C228.679 184.302 227.906 184.118 226.972 184.118C226.038 184.118 225.222 184.302 224.523 184.662C223.825 185.022 223.244 185.522 222.773 186.154C222.302 186.787 221.957 187.522 221.721 188.345C221.486 189.168 221.368 190.028 221.368 190.918V208.09H210.873V176.422H220.986V180.81H221.111C221.493 180.134 221.993 179.465 222.604 178.811C223.222 178.157 223.942 177.591 224.766 177.128C225.59 176.664 226.494 176.282 227.473 175.981C228.451 175.687 229.488 175.532 230.591 175.532C232.709 175.532 234.511 175.915 235.997 176.679C237.482 177.444 238.681 178.429 239.593 179.634C240.505 180.84 241.16 182.229 241.564 183.802C241.969 185.368 242.167 186.941 242.167 188.507V208.09H231.672L231.687 208.097Z"
            />
            <path
              d="M280.426 192.645V193.917C280.426 194.343 280.404 194.74 280.36 195.123H257.391C257.472 196.012 257.744 196.821 258.186 197.541C258.634 198.261 259.201 198.886 259.907 199.415C260.605 199.945 261.392 200.356 262.26 200.658C263.128 200.952 264.033 201.106 264.967 201.106C266.621 201.106 268.019 200.797 269.166 200.187C270.313 199.57 271.24 198.776 271.968 197.806L279.22 202.385C277.734 204.546 275.771 206.208 273.336 207.376C270.894 208.545 268.063 209.126 264.842 209.126C262.466 209.126 260.215 208.751 258.097 208.016C255.979 207.274 254.118 206.193 252.53 204.774C250.941 203.356 249.691 201.606 248.779 199.526C247.867 197.453 247.411 195.071 247.411 192.403C247.411 189.734 247.86 187.47 248.75 185.375C249.639 183.28 250.846 181.494 252.375 180.038C253.905 178.576 255.707 177.444 257.781 176.635C259.862 175.834 262.106 175.429 264.525 175.429C266.945 175.429 268.997 175.819 270.953 176.606C272.902 177.392 274.579 178.524 275.977 180.009C277.374 181.494 278.47 183.295 279.257 185.412C280.044 187.529 280.433 189.947 280.433 192.66L280.426 192.645ZM270.821 188.639C270.821 186.985 270.299 185.566 269.262 184.376C268.225 183.192 266.666 182.597 264.584 182.597C263.569 182.597 262.635 182.758 261.782 183.074C260.936 183.39 260.193 183.824 259.554 184.376C258.921 184.927 258.406 185.574 258.024 186.316C257.641 187.059 257.428 187.83 257.391 188.639H270.814H270.821Z"
            />
            <path
              d="M307.146 208.097L300.335 198.372L293.341 208.097H281.25L293.907 191.499L282.456 176.429H294.606L300.519 184.883L306.498 176.429H318.266L306.881 191.499L319.413 208.097H307.138H307.146Z"
            />
            <path
              d="M336.601 184.192V196.321C336.601 197.813 336.888 198.93 337.462 199.673C338.035 200.415 339.065 200.79 340.543 200.79C341.051 200.79 341.595 200.746 342.169 200.665C342.742 200.584 343.22 200.452 343.603 200.283L343.728 207.913C343.007 208.163 342.095 208.391 340.992 208.582C339.889 208.773 338.785 208.869 337.682 208.869C335.564 208.869 333.777 208.604 332.335 208.075C330.894 207.546 329.739 206.781 328.871 205.781C328.003 204.782 327.378 203.591 326.996 202.209C326.613 200.827 326.422 199.283 326.422 197.585V184.199H321.333V176.444H326.356V168.115H336.601V176.444H344.044V184.199H336.601V184.192Z"
            />
            <path
              d="M340.411 151.098C340.999 151.098 341.521 151.039 341.521 150.348C341.521 149.767 340.955 149.701 340.477 149.701H339.536V151.098H340.411ZM339.536 153.81H338.749V149.032H340.558C341.713 149.032 342.301 149.437 342.301 150.4C342.301 151.267 341.771 151.627 341.058 151.708L342.404 153.803H341.514L340.278 151.767H339.536V153.803V153.81ZM340.389 147.775C338.521 147.775 337.064 149.246 337.064 151.348C337.064 153.303 338.337 154.906 340.389 154.906C342.235 154.906 343.684 153.45 343.684 151.348C343.684 149.246 342.235 147.775 340.389 147.775ZM340.389 155.656C337.903 155.656 336.138 153.796 336.138 151.348C336.138 148.746 338.101 147.026 340.389 147.026C342.676 147.026 344.618 148.746 344.618 151.348C344.618 153.95 342.654 155.656 340.389 155.656Z"
            />
            <path
              d="M91.139 111.851C90.6757 95.6721 74.9514 86.4614 60.448 86.4614C40.1197 86.4614 26.9254 101.972 26.9254 121.459C26.9254 140.946 40.1197 156.464 60.448 156.464C77.4961 156.464 90.1976 146.298 91.139 128.986H73.6349C72.9804 136.69 68.2807 141.417 60.0803 141.417C48.7761 141.417 44.4442 131.434 44.4442 121.459C44.4442 111.484 48.7761 101.501 60.0803 101.501C67.4202 101.501 72.6935 105.074 73.6349 111.844H91.139V111.851Z"
            />
            <path
              d="M93.3601 87.8654H109.364V98.2596V125.59V155.053H93.3601V121.466V97.6054V87.8654Z"
            />
            <path
              d="M127.015 141.02C127.015 137.168 130.016 135.661 133.59 134.816C137.091 133.875 141.232 133.595 143.468 132.191C143.857 142.262 141.32 146.57 133.693 146.57C130.111 146.57 127.015 144.982 127.015 141.012V141.02ZM127.942 121.268C128.699 117.122 131.524 115.137 135.944 115.137C139.253 115.137 143.666 116.578 143.666 120.224C143.666 123.238 142.166 124.186 139.415 124.745C128.324 127.002 110.997 125.774 110.997 141.681C110.997 151.76 118.351 156.471 127.662 156.471C133.487 156.471 139.606 154.766 143.754 150.444C143.946 152.024 144.129 153.553 144.703 155.053H160.795C158.92 151.186 158.92 146.68 158.92 142.534V121.172C158.92 107.353 147.711 104.795 136.128 104.795C130.589 104.795 124.926 105.743 120.594 108.198C116.277 110.727 113.247 114.873 112.982 121.268H127.934H127.942Z"
            />
            <path
              d="M208.372 106.206H192.001L182.771 138.028H182.58L173.372 106.206H156.419L169.04 140.197C169.974 142.828 173.085 150.348 173.085 152.98C173.085 156.369 170.356 158.441 166.701 158.441H161.141V171.431H168.65C179.483 171.431 185.235 170.1 189.089 159.39L208.38 106.206H208.372Z"
            />
            <path
              d="M235.445 106.206H225.538V91.4453H209.549V106.206H201.452V116.585H209.549V138.307C209.549 151.583 213.028 155.523 226.678 155.523C229.605 155.523 232.518 155.148 235.445 155.053V143.284C234.026 143.284 232.599 143.468 231.304 143.468C227.811 143.468 225.531 142.63 225.531 138.778V116.585H235.438V106.206H235.445Z"
            />
            <path
              d="M249.22 130.684C249.22 124.458 251.478 117.034 258.921 117.034C266.364 117.034 268.71 124.458 268.71 130.684C268.71 136.911 266.349 144.232 258.921 144.232C251.493 144.232 249.22 136.896 249.22 130.684ZM233.216 130.684C233.216 145.357 243.77 156.471 258.921 156.471C274.072 156.471 284.714 145.357 284.714 130.684C284.714 116.012 274.079 104.795 258.921 104.795C243.763 104.795 233.216 115.997 233.216 130.684Z"
            />
            <path
              d="M286.067 155.053H302.071V128.42C302.071 123.054 304.711 117.989 310.735 117.989C319.494 117.989 318.737 124.752 318.737 131.442V155.06H334.74V123.716C334.74 116.674 333.424 104.802 317.133 104.802C311.308 104.802 304.711 107.911 301.791 112.903H301.615V106.206H286.067V155.053Z"
            />
            <path
              d="M133.002 87.2479L217.316 54.6246L301.63 87.2479V93.3345L217.316 75.4056L133.002 93.3345V87.2479Z"
            />
          </svg>
          <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122 37">
            <path
              d="M0 0.771431H2.54362L23.4013 33.3249H23.4938V0.771431H25.5287V36.2571H23.1239L2.12739 3.45523H2.0349V36.2571H0V0.771431ZM37.1247 0.771431H59.3236V2.66003H39.1596V16.8742H58.1212V18.7628H39.1596V34.3685H59.5548V36.2571H37.1247V0.771431ZM65.9171 0.771431H68.2757L78.7277 16.3772L89.4109 0.771431H91.7233L80.0689 18.0173L92.3708 36.2571H89.9197L78.7277 19.6574L67.4895 36.2571H65.0846L77.479 18.0173L65.9171 0.771431ZM96.7025 2.66003V0.771431H122V2.66003H110.346V36.2571H108.311V2.66003H96.7025Z"
            />
          </svg> -->
        </div>

        @if (navItems && navItems.length > 0) { @for (item of navItems; track
        item) {
        <nav-item
          [name]="item.Name"
          [active]="item.Active"
          [iconUrl]="item.IconUrl"
          [url]="item.Url"
        ></nav-item>
        } }
      </mat-sidenav>
      <mat-sidenav-content>
        <ng-content></ng-content>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
  styles: [
    `
      .container {
        height: 100vh;
      }
      .navbar {
        //box-shadow: var(--box-shadow);
        width: 150px;
        background: var(--nav-menu-background);
        overflow-y: auto;

        #logo-container {
          display: flex;
          position: relative;
          justify-content: center;
          align-items: center;
          margin: 0 auto;

          width: 100%;
          height: 70px;

          svg {
            fill: var(--logo-color);
            width: 80%;
          }

          img {
            width: 100%;
          }
        }
      }
    `,
  ],
  standalone: true,
})
export class NavBarComponent {
  navItems: NavItem[] = [];

  constructor(private navService: NavService) {
    this.navService.navItems$.subscribe((i) => {
      this.navItems = i;
    });
  }
}
