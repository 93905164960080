<content-panel>
  <panel-header heading="Credit Submissions">
    <button mat-icon-button (click)="drawerSvc.openFilter()">
      <mat-icon>download</mat-icon>
    </button>
    <button mat-icon-button (click)="drawerSvc.openFilter()">
      <mat-icon>filter_alt</mat-icon>
    </button>
    <button mat-icon-button (click)="drawerSvc.openFilter()">
      <mat-icon>add</mat-icon>
    </button>
  </panel-header>

  <panel-body>
    <div class="container">
      @if(isDetailOpen) { @for (element of dataSource.data; track element.Id) {
      <section
        mat-ripple
        class="list-row-container"
        [ngClass]="submission?.Id === element.Id ? 'active' : ''"
        (click)="rowSelected(element.Id)"
      >
        <h2 class="primary-content-container">
          {{ element.FacilityNumber }} - {{ element.SerialNumber }}
        </h2>
        <div class="secondary-content-container">
          <div [style.width.%]="33">
            Version:
            <div class="secondary-data-bold">{{ element.Version }}</div>
          </div>
          <div [style.width.%]="33">
            Status:
            <div class="secondary-data-bold">{{ element.Status }}</div>
          </div>
          <div [style.width.%]="33">
            Sold On:
            <div class="secondary-data-bold">{{ element.SoldDate | date }}</div>
          </div>
        </div>
      </section>
      } } @else {
      <section>
        <table mat-table [dataSource]="dataSource">
          @for (column of tableColumns; track column) {
          <ng-container [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef>
              @if(column === 'FacilityNumber') {
              <b>{{ "Facility #" }}</b>
              } @else if(column === 'OrderNumber') {
              <b>{{ "Order #" }}</b>
              } @else if(column === 'SerialNumber') {
              <b>{{ "Serial #" }}</b>
              } @else if(column === 'HomeCenterName') {
              <b>{{ "Home Center" }}</b>
              }@else if(column === 'CustomerName') {
              <b>{{ "Customer" }}</b>
              }@else {
              <b>{{ column | tableColumn }}</b>
              }
            </th>
            <td mat-cell *matCellDef="let element">
              @if(column === 'SoldDate') {
              {{ element[column] | date }}
              } @else if(column === 'CertificateMailed') {
              {{ element[column] ? "Yes" : "No" }}
              }@else {
              {{ element[column] }}
              }
            </td>
          </ng-container>
          }
          <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: tableColumns"
            (click)="rowSelected(row.Id)"
          ></tr>
        </table>
      </section>
      }
    </div>
  </panel-body>
  @if(!isDetailOpen){
  <mat-paginator
    [pageSizeOptions]="paginatorOptions.pageSizeOptions"
    [pageSize]="paginatorOptions.pageSize"
    [length]="paginatorOptions.pageLength"
    [showFirstLastButtons]="paginatorOptions.showFirstLastButtons"
    (page)="pageChange.emit($event)"
  >
  </mat-paginator>
  } @else {
  <mat-paginator
    [pageSize]="paginatorOptions.pageSize"
    [length]="paginatorOptions.pageLength"
    (page)="pageChange.emit($event)"
  >
  </mat-paginator>
  }
</content-panel>
