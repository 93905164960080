import { Injectable } from '@angular/core';
import { DynamicFormControl } from '../models/dynamic-form-control.model';
import { FormControl } from '@angular/forms';
import { ControlType } from '../models/control-type.enum';

@Injectable({
  providedIn: 'root',
})
export class FormControlFactory {
  /**
   * Creates a DynamicFormControl for the provided values.
   * @param controlType
   * @param label
   * @param propertyBinding
   * @param defaultValue
   * @param customOptions
   * @param tooltip
   * @returns
   */
  create(
    controlType: ControlType,
    label?: string,
    propertyBinding?: string,
    defaultValue?: any,
    customOptions?: any,
    tooltip?: string
  ): DynamicFormControl {
    let control = new DynamicFormControl();
    control.controlType = controlType;
    control.label = label;
    control.defaultValue = defaultValue;
    control.propertyBinding = propertyBinding;
    control.tooltip = tooltip;

    if (customOptions) {
      if (!Object.hasOwn(customOptions, 'isVisibleByDefault')) {
        customOptions['isVisibleByDefault'] = true;
      }
    } else {
      customOptions = {
        isVisibleByDefault: true,
      };
    }

    control.customOptions = customOptions;
    if (!control.customOptions.isVisibleByDefault) {
      control.hidden = true;
    }

    control.formControls = [
      {
        propertyBinding: propertyBinding,
        formControl: new FormControl(defaultValue),
      },
    ];

    return control;
  }

  /**
   * Creates a TextBox DynamicFormControl.
   * @param label
   * @param propertyBinding
   * @param defaultValue
   * @param customOptions
   * @param tooltip
   * @returns
   */
  createTextBox(
    label?: string,
    propertyBinding?: string,
    defaultValue?: any,
    customOptions?: any,
    tooltip?: string
  ): DynamicFormControl {
    return this.create(
      ControlType.INPUT,
      label,
      propertyBinding,
      defaultValue,
      customOptions,
      tooltip
    );
  }

  /**
   * Creates a MultiSelectBox using DynamicFormControl.
   * @param label
   * @param propertyBinding
   * @param defaultValue
   * @param customOptions
   * @param tooltip
   * @returns
   */
  createMultiSelectBox(
    label?: string,
    propertyBinding?: string,
    defaultValue?: any,
    customOptions?: any,
    tooltip?: string
  ): DynamicFormControl {
    var ctrl = this.create(
      ControlType.MULTISELECT,
      label,
      propertyBinding,
      defaultValue,
      customOptions,
      tooltip
    );

    ctrl.formControls = [
      ...ctrl.formControls,
      {
        propertyBinding: `${ctrl.propertyBinding}_multiselect_autocomplete_ctrl`,
        formControl: new FormControl(ctrl.defaultValue),
      },
    ];

    return ctrl;
  }

  /**
   * Creates a SelectBox DynamicFormControl.
   * @param label
   * @param propertyBinding
   * @param defaultValue
   * @param customOptions
   * @param tooltip
   * @returns
   */
  createSelectBox(
    label?: string,
    propertyBinding?: string,
    defaultValue?: any,
    customOptions?: any,
    tooltip?: string
  ): DynamicFormControl {
    return this.create(
      ControlType.SELECT,
      label,
      propertyBinding,
      defaultValue,
      customOptions,
      tooltip
    );
  }

  /**
   * Creates a DatePicker DynamicFormControl.
   * @param label
   * @param propertyBinding
   * @param defaultValue
   * @param customOptions
   * @param tooltip
   * @returns
   */
  createDatePicker(
    label?: string,
    propertyBinding?: string,
    defaultValue?: any,
    customOptions?: any,
    tooltip?: string
  ): DynamicFormControl {
    return this.create(
      ControlType.DATE,
      label,
      propertyBinding,
      defaultValue,
      customOptions,
      tooltip
    );
  }

  /**
   * Creates a DateRange DynamicFormControl
   * @param label
   * @param propertyBinding
   * @param defaultValue
   * @param customOptions
   * @param tooltip
   * @returns
   */
  createDateRangePicker(
    label?: string,
    propertyBinding?: string,
    defaultValue?: any,
    customOptions?: any,
    tooltip?: string
  ): DynamicFormControl {
    let ctrl = this.create(
      ControlType.DATERANGE,
      label,
      propertyBinding,
      defaultValue,
      customOptions,
      tooltip
    );

    ctrl.formControls = [
      ...ctrl.formControls,
      {
        propertyBinding: `${ctrl.propertyBinding}_start_input_ctrl`,
        formControl: new FormControl(ctrl.customOptions['dateDefaults']?.start),
      },
      {
        propertyBinding: `${ctrl.propertyBinding}_end_input_ctrl`,
        formControl: new FormControl(ctrl.customOptions['dateDefaults']?.end),
      },
    ];

    return ctrl;
  }
}
