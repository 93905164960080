<lib-active-filters></lib-active-filters>
<div class="panel-flex">
  <app-users-list-view
    [@toggle-main-panel]="isDetailOpen ? 'open' : 'closed'"
    [isDetailOpen]="isDetailOpen"
    [user]="user"
    (selectedUser)="selectUser($event)"
    (pageChange)="onPageChange($event)"
    (sortChange)="onPageSort($event)"
  ></app-users-list-view>
  <app-user-detail-view
    [@toggle-detail-panel]="isDetailOpen ? 'open' : 'closed'"
    [user]="user"
    (close)="closeDetail()"
  ></app-user-detail-view>
</div>
