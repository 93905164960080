import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { DynamicFormControlComponent } from '../dynamic-form-control/dynamic-form-control.component';
import { DynamicFormGroup } from '../../models/dynamic-form-group.model';
import { DynamicFormControl } from '../../models/dynamic-form-control.model';

@Component({
  selector: 'lib-dynamic-form-group',
  standalone: true,
  imports: [NgClass, DynamicFormControlComponent],
  templateUrl: './dynamic-form-group.component.html',
  styleUrl: './dynamic-form-group.component.scss',
})
export class DynamicFormGroupComponent {
  @Input()
  group: DynamicFormGroup;

  @Input()
  nested: boolean;

  ngOnInit() {}

  get controls() {
    return this.group?.children.filter(
      (i) => i instanceof DynamicFormControl
    ) as DynamicFormControl[];
  }

  get nestedGroups() {
    return this.group?.children.filter(
      (i) => i instanceof DynamicFormGroup
    ) as DynamicFormGroup[];
  }
}
