<div class="pill-container">
  <mat-label>{{ control.label }}</mat-label>
  <div
    id="pill-button-group"
    class="button-group-container"
    [class.invalid]="formControl?.invalid"
  >
    @for (item of options; track item.value) {
    <div class="button-group">
      <div matRipple [matRippleColor]="rippleColor">
        <button
          type="button"
          [class.active]="item.active"
          (click)="setValue(item.value)"
        >
          {{ item.text }}
        </button>
      </div>
    </div>
    }

    <ng-content></ng-content>
  </div>
</div>
