import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from 'src/app/shared/material';
import { Subscription } from 'rxjs';
import { DynamicFormControl } from '../../models/dynamic-form-control.model';
import { FilterService } from 'src/app/shared/filtering';

@Component({
  selector: 'lib-text-box-control',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, MatInputModule],
  templateUrl: './text-box-control.component.html',
  styleUrl: './text-box-control.component.scss',
})
export class TextBoxControlComponent {
  @Input() control: DynamicFormControl;
  @Input() isReadonly: boolean;
  inputControl: FormControl;

  private _subscriptions: Subscription[] = [];

  constructor(private filterService: FilterService) {}

  ngOnInit() {
    this.inputControl = this.control.formControls[0].formControl;

    let sub = this.filterService.activeFilterRemoved$.subscribe({
      next: (i) => {
        if (i.propertyBinding === this.control.propertyBinding) {
          this.control.formControls[0].formControl.setValue(null);
        }
      },
      error: (e) => console.log(e),
    });

    this._subscriptions.push(sub);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((i) => i.unsubscribe());
  }
}
