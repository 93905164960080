<mat-form-field>
  <mat-label>{{ control.customOptions?.placeholder }}</mat-label>
  <mat-date-range-input [rangePicker]="picker">
    <input
      matStartDate
      [formControl]="startDateControl"
      placeholder="Start date"
    />
    <input matEndDate [formControl]="endDateControl" placeholder="End date" />
  </mat-date-range-input>
  <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
