import { Component, OnInit } from '@angular/core';
import { PageErrorState } from './models/pageErrorState.model';

@Component({
  selector: 'app-error',
  standalone: true,
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
})
export class ErrorComponent implements OnInit {
  themeIsLight: boolean;
  errorTitle: string;
  errorMessage: string;

  constructor() {}

  ngOnInit(): void {
    let errorState = window.history.state as PageErrorState;
    this.errorTitle = errorState?.errorTitle;
    this.errorMessage = errorState?.errorMessage;
  }
}
