<lib-active-filters></lib-active-filters>
<div class="panel-flex">
  <app-submissions-list-view
    [@toggle-main-panel]="isDetailOpen ? 'open' : 'closed'"
    (selectedSubmission)="selectSubmission($event)"
    (pageChange)="onPageChange($event)"
    [submission]="submission"
    [isDetailOpen]="isDetailOpen"
  >
  </app-submissions-list-view>
  <app-submissions-detail-view
    [@toggle-detail-panel]="isDetailOpen ? 'open' : 'closed'"
    (close)="closeDetail()"
  ></app-submissions-detail-view>
</div>
