import { Component } from '@angular/core';

@Component({
  selector: 'panel-body',
  standalone: true,
  imports: [],
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
        overflow-y: auto;
      }
    `,
  ],
})
export class PanelBodyComponent {}
