import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class RolesGuard {
  constructor(private authService: MsalService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const expectedRoles = route.data['expectedRoles'];
    let roles =
      this.authService.instance.getAllAccounts()[0].idTokenClaims?.roles;
    if (!roles) {
      return false;
    } else if (!roles.some((r) => expectedRoles.includes(r))) {
      return false;
    }

    return true;
  }
}
