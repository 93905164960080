import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { FilterDrawerComponent } from 'src/app/shared/filtering';
import { NavBarComponent } from 'src/app/shared/navigation';
import { UserSettingsComponent } from 'src/app/shared/user-settings';
import {
  PageContentComponent,
  PageHeaderComponent,
} from 'src/app/shared/page-container';
import {
  MatDrawer,
  MatProgressSpinnerModule,
  MatSidenavModule,
} from 'src/app/shared/material';
import {
  DrawerService,
  SpinnerService,
  ThemeService,
} from 'src/app/shared/services';

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [
    CommonModule,
    NavBarComponent,
    PageHeaderComponent,
    PageContentComponent,
    RouterOutlet,
    MatProgressSpinnerModule,
    MatSidenavModule,
    UserSettingsComponent,
    FilterDrawerComponent,
  ],
})
export class HomeComponent {
  @ViewChild(MatDrawer) drawer: MatDrawer;

  constructor(
    public themeService: ThemeService,
    public drawerService: DrawerService,
    public spinnerService: SpinnerService
  ) {}

  ngOnInit() {}

  updateDrawerState() {
    this.drawerService.state.set(false);
  }
}
