<form #form="ngForm" [formGroup]="userPreferenceForm" >
  <mat-radio-group
  formControlName="theme"
  class="theme-selection"
  (change)="loadTheme($event.value)"
  >
    <div class="rbtn-card-container">
      <mat-card class="theme-card">
        <img
          mat-card-image
          src="./assets/images/dark_mode_theme.png"
          alt="Dark Mode Theme"
        />
      </mat-card>
      <mat-radio-button value="dark"> Dark </mat-radio-button>
    </div>
    <div class="rbtn-card-container">
      <mat-card class="theme-card">
        <img
          mat-card-image
          src="./assets/images/light_mode_theme.png"
          alt="Light Mode Theme"
        />
      </mat-card>
      <mat-radio-button value="light"> Light </mat-radio-button>
    </div>
  </mat-radio-group>
  
  <mat-form-field class="full-width">
    <mat-label>Default URL</mat-label>
    <input matInput type="text" formControlName="defaultUrl" />
  </mat-form-field>

  <div>
    <button mat-button type="button" (click)="onSubmit(userPreferenceForm.value)">Save</button>
  </div>
</form>