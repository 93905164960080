import { Component, Input, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { MatLabel, MatRippleModule } from 'src/app/shared/material';
import { DynamicFormControl } from '../../models/dynamic-form-control.model';

export interface PillButtonOption {
  text?: string;
  value?: any;
  active?: boolean;
}

@Component({
  selector: 'lib-pill-button-group',
  standalone: true,
  imports: [ReactiveFormsModule, MatRippleModule, MatLabel],
  templateUrl: './pill-button-group.component.html',
  styleUrls: ['./pill-button-group.component.scss'],
})
export class PillButtonGroupComponent implements OnInit {
  @Input() control: DynamicFormControl;
  options: PillButtonOption[] = [];
  rippleColor: string = 'rgba(255,255,255,0.08)';
  formControl: FormControl;

  constructor() {}

  ngOnInit(): void {
    this.formControl = this.control.formControls[0].formControl;
    this.options = this.control.customOptions.selectOptions.map((i: any) =>
      Object.create({
        text: i.label,
        value: i.value,
        active: i.value.toString() === this.formControl.value.toString(),
      })
    );

    this.validateOptions();
  }

  setValue(value: any) {
    //Clear out any active options.
    this.options
      .filter((i) => i.value !== value)
      .forEach((x) => (x.active = false));

    //Set active option.
    let selectedOpt = this.options.find((i) => i.value === value)!;
    selectedOpt.active = true;

    this.formControl?.setValue(value);
  }

  validateOptions() {
    if (this.options.length < 2) {
      throw new Error('Pill element must have at least 2 options.');
    } else if (this.options.length > 3) {
      throw new Error(
        'Pill element cannont container more than 3 options. Use the Dropdown Component instead.'
      );
    } else if (this.options.filter((i) => i.active).length > 1) {
      throw new Error('Cannot contain more than one active option at a time.');
    }
  }
}
