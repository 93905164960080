import { FormControl } from '@angular/forms';
import { ControlType } from './control-type.enum';

//
export class DynamicFormControl {
  controlType?: ControlType;
  customOptions?: any;
  label?: string;
  propertyBinding?: string;
  defaultValue?: any;
  tooltip?: string;

  //Control behavior options
  isRequired?: boolean;
  hidden?: boolean = false;
  touched?: boolean = false;

  formControls?: { propertyBinding?: string; formControl?: FormControl }[];

  markAsTouched() {
    this.touched = true;
  }
  markAsPristine() {
    this.touched = false;
  }
  hideControl() {
    this.hidden = true;
  }
  showControl() {
    this.hidden = false;
  }

  addFormControl(propertyBinding: string, value?: any) {
    this.formControls = [
      ...this.formControls,
      {
        propertyBinding: propertyBinding,
        formControl: new FormControl(value),
      },
    ];
  }
}
