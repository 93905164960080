import { Component, OnInit, effect } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Subject, catchError, filter, switchMap, takeUntil } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Profile, ProfileService } from './shared/services/profile.service';
import { WebSocketService } from './shared/services/web-socket.service';
import { TokenClaimsService } from './shared/services/token-claims.service';
import { UsersService } from './modules/users/services/users.service';
import { PageHeaderService } from './shared/services/page-header.service';
import { NavService } from './shared/navigation';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  template: ` <router-outlet></router-outlet>`,
  standalone: true,
})
export class AppComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private profileService: ProfileService,
    private wsService: WebSocketService,
    private claimsService: TokenClaimsService,
    private userService: UsersService,
    private navService: NavService,
    private pageHeaderService: PageHeaderService
  ) {}

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.INITIALIZE_END
        ),
        switchMap((i) => this.profileService.getProfile()),
        takeUntil(this._destroying$)
      )
      .subscribe((result: Profile) => {
        this.profileService.profile$.set(result);
        this.profileService.selfProvisionUser(result);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.HANDLE_REDIRECT_END
        ),
        switchMap((i) => {
          const accessTokenRequest = {
            scopes: ['user.read'],
            account: this.authService.instance.getAllAccounts()[0],
            forceRefresh: false,
          };
          return this.authService.acquireTokenSilent(accessTokenRequest).pipe(
            catchError(() => {
              accessTokenRequest.forceRefresh = true;
              return this.authService.acquireTokenSilent(accessTokenRequest);
            })
          );
        }),
        switchMap((accessTokenRequest) => {
          this.claimsService.setTokenClaim(accessTokenRequest.idTokenClaims);
          this.claimsService.setPermissions(accessTokenRequest.idTokenClaims);
          this.navService.createNavBar();
          this.pageHeaderService.setIsAdmin();
          this.userService.loadUserPreferences();
          return this.wsService.connect(accessTokenRequest.idToken);
        }),
        takeUntil(this._destroying$)
      )
      .subscribe();
  }
}
