import { Injectable } from '@angular/core';
import { permissionConstants } from 'src/app/shared/services';

export type TokenClaim = {
  aud: string;
  iss: string;
  iat: number;
  nbf: number;
  exp: number;
  name: string;
  oid: string;
  preferred_username: string;
};

@Injectable({
    providedIn: 'root',
})
export class TokenClaimsService {
    tokenClaims$: TokenClaim;
    canAccessEC: boolean = false;
    isAdmin: boolean = false;

  constructor() {}

  setTokenClaim(idTokenClaims: any): void {
    this.tokenClaims$ = idTokenClaims as TokenClaim;
  }

  setPermissions(tokenClaims: any): void {
    this.isAdmin = tokenClaims.roles?.find((x: string) => x == permissionConstants.siteAdmin) ? true : false;
    this.canAccessEC = this.isAdmin || tokenClaims.roles?.some((x: string) => permissionConstants.energyCredits.includes(x));
  }
}
