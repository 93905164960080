import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription, of, switchMap } from 'rxjs';
import { User, UserDataSource } from '../models/user';
import { UsersService } from '../services/users.service';
import {
  PanelBodyComponent,
  PanelContainerComponent,
  PanelHeaderComponent,
} from 'src/app/shared/content-panels';
import { DynamicFormControl, FormControlFactory } from 'src/app/shared/forms';
import { FilterService } from 'src/app/shared/filtering';
import { OrderByPipe, TableColumnPipe } from 'src/app/shared/pipes';
import {
  MatIconModule,
  MatPaginatorModule,
  MatRipple,
  MatSortModule,
  MatTableDataSource,
  MatTableModule,
  PageEvent,
  Sort,
} from 'src/app/shared/material';
import { DrawerService } from 'src/app/shared/services';
import { Pagination } from 'src/app/shared/models';

@Component({
  selector: 'app-users-list-view',
  standalone: true,
  imports: [
    CommonModule,
    OrderByPipe,
    TableColumnPipe,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatRipple,
    MatPaginatorModule,
    PanelContainerComponent,
    PanelBodyComponent,
    PanelHeaderComponent,
  ],
  templateUrl: './users-list-view.component.html',
  styleUrl: './users-list-view.component.scss',
})
export class UsersListViewComponent {
  @Input() isDetailOpen = false;
  @Input() user: User;
  @Output() selectedUser = new EventEmitter<User>();
  @Output() pageChange = new EventEmitter<PageEvent>();
  @Output() sortChange = new EventEmitter<Sort>();

  usersTableColumns: any[] = [
    {id: 'Id', label: 'Id'},
    {id: 'FirstName', label: 'First Name'},
    {id: 'LastName', label: 'Last Name'},
    {id: 'Email', label: 'Email'},
    {id: 'OfficeLocation', label: 'Default Location'}
  ];

  mapUsersDisplayedColumns: string[] = this.usersTableColumns.map(col => col.id);

  pagination: Pagination = {
    paging: true,
    pageSizeOptions: [50, 100, 500],
    pageSize: 50,
    sortBy: 'LastName',
    sortDirection: 'asc',
  }

  dataSource = new MatTableDataSource<UserDataSource>([]);
  isEditMode = false;
  allAssigned = false;

  private _subscriptions: Subscription[] = [];
  private _userCollection: User[] = [];
  private _filterControls: DynamicFormControl[];

  constructor(
    public userSrv: UsersService,
    public drawerSvc: DrawerService,
    private filterSvc: FilterService,
    private route: ActivatedRoute,
    private filterCtrlFactory: FormControlFactory
  ) {}

  ngOnInit() {
    this._filterControls = this.buildControls();
    this.filterSvc.setFilterFormControls(this._filterControls);

    let sub = this.userSrv.facilities$
      .pipe(
        switchMap((result) => {
          return of(
            this.filterCtrlFactory.createMultiSelectBox(
              'Facilities',
              'FacilityAssignments',
              null,
              {
                placeholder: 'Select a Facility',
                selectOptions: result.flatMap((i) => ({
                  value: i.Id,
                  label: i.KeyChain.LegacyId,
                })),
              }
            )
          );
        })
      )
      .subscribe((ctrl) => {
        if (
          this._filterControls
            .map((i) => i.propertyBinding)
            .indexOf(ctrl.propertyBinding) === -1
        ) {
          this._filterControls.push(ctrl);
          this.filterSvc.setFilterFormControls(this._filterControls);
        }
      });

    sub = this.userSrv.users$.subscribe((result) => {
      this.pagination.totalCount = result.TotalCount;
      this._userCollection = result?.Items?.map((i) => new User(i));
      if (!this.user) {
        this.checkForSelectedUser();
      }
      this.updateDataTable();
    });
    this._subscriptions.push(sub);
  }

  selectUser(user: User) {
    this.user = user;
    if (this.user.FacilityAssignments?.length > 0) {
      this.user.FacilityAssignments = [
        ...this.user.FacilityAssignments?.sort(
          (a, b) =>
            parseInt(a.Facility.KeyChain.LegacyId) - parseInt(b.Facility.KeyChain.LegacyId)
        ),
      ];
    }

    this.selectedUser.emit(this.user);
  }

  rowSelected(userId: number | string) {
    let user = this._userCollection.find((i) => i.Id === userId);
    this.selectUser(user);
  }

  private lookupUser(userId: number | string) {
    let sub = this.userSrv.getUserById(userId).subscribe((user) => {
      if (user) {
        this.pagination.pageSize += 1;
        this.selectUser(user);
        this.updateDataTable();
      }
    });
    this._subscriptions.push(sub);
  }

  private updateDataTable() {
    this.dataSource.data = this._userCollection?.flatMap((i) =>
      new UserDataSource().convertFromUser(i)
    );
  }

  private checkForSelectedUser() {
    var userId = this.route.snapshot.queryParamMap.get('id');
    if (userId) {
      let user = this._userCollection?.find((i) => i.Id.toString() === userId);
      if (user) {
        this.selectUser(user);
      } else {
        this.lookupUser(userId);
      }
    }
  }

  private buildControls(): DynamicFormControl[] {
    return [
      this.filterCtrlFactory.createTextBox('First Name', 'FirstName', '', {
        placeholder: 'Enter a First Name',
      }),
      this.filterCtrlFactory.createTextBox('Last Name', 'LastName', '', {
        placeholder: 'Enter a Last Name',
      }),
      this.filterCtrlFactory.createTextBox('Email', 'Email', '', {
        placeholder: 'Enter an Email',
      }),
    ];
  }
}
