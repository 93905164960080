import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { localStorageConstants } from './constants';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  currentTheme: string = 'light';
  constructor(@Inject(DOCUMENT) private document: Document) {}

  set(theme: string) {
    let preference = JSON.parse(
      localStorage.getItem(localStorageConstants.USER_PREFERENCE_KEY)
    );
    this.currentTheme = theme;
    if (preference) {
      preference.theme = theme;
      localStorage.setItem(
        localStorageConstants.USER_PREFERENCE_KEY,
        JSON.stringify(preference)
      );
    }
    this.loadTheme(theme);
  }

  loadUserTheme() {
    const pref = this.get();
    this.set(pref);
  }

  get() {
    let pref = JSON.parse(
      localStorage.getItem(localStorageConstants.USER_PREFERENCE_KEY)
    );
    return pref?.theme || this.currentTheme;
  }

  private loadTheme(themeName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const themeSrc = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;

    if (themeSrc) {
      themeSrc.href = `${themeName}.css`;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${themeName}.css`;

      head.appendChild(style);
    }
  }

  toggleTheme() {
    if (this.currentTheme === 'light') {
      this.currentTheme = 'dark';
      this.loadTheme('dark');
    } else {
      this.currentTheme = 'light';
      this.loadTheme('light');
    }
  }
}
