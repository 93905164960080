<content-panel>
  <panel-header [heading]="user?.LastName + ', ' + user?.FirstName">
    <button mat-icon-button *ngIf="isEditMode" (click)="saveChanges()">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-icon-button *ngIf="!isEditMode" (click)="toggleEditMode()">
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-icon-button (click)="closePanel()">
      <mat-icon>close</mat-icon>
    </button>
  </panel-header>
  <panel-body class="detail-view-container">
    <section class="row">
      <section class="column">
        <div class="body">
          <div class="detail-view-element">
            <label for="">ID</label>
            <div>{{ user?.Id }}</div>
          </div>
          <div class="detail-view-element">
            <label for="">First Name</label>
            <div>{{ user?.FirstName }}</div>
          </div>
          <div class="detail-view-element">
            <label for="">Last Name</label>
            <div>{{ user?.LastName }}</div>
          </div>
        </div>
      </section>
      <section class="column">
        <div class="body">
          <div class="detail-view-element">
            <label for="">Office</label>
            <div>{{ user?.DefaultLocation }}</div>
          </div>
          <div class="detail-view-element">
            <label for="">Email</label>
            <div>{{ user?.Email }}</div>
          </div>
          <div class="detail-view-element">
            <label for="">Last Login</label>
            <div>{{ user?.LastLogin | date }}</div>
          </div>
        </div>
      </section>
      <section class="column">
        <div class="body">
          <div class="detail-view-element">
            <label for="">Created By</label>
            <div>{{ user?.CreatedBy }}</div>
          </div>
          <div class="detail-view-element">
            <label for="">Created On</label>
            <div>{{ user?.CreatedOn | date }}</div>
          </div>
        </div>
      </section>
    </section>
    <section class="row">
      <div class="header no-border">Assigned Facilities</div>
    </section>
    <section class="row scrollable">
      <table
        *ngIf="!isEditMode"
        class="no-pointer"
        mat-table
        [dataSource]="user?.FacilityAssignments"
      >
        <ng-container matColumnDef="Id">
          <th mat-header-cell *matHeaderCellDef><b>Id</b></th>
          <td mat-cell *matCellDef="let element">
            {{ element.Facility.Id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="LegacyId">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>
            <b>Number</b>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.Facility.KeyChain.LegacyId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef><b>Name</b></th>
          <td mat-cell *matCellDef="let element">
            {{ element.Facility.Name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="IsDefault">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            {{ element.IsDefault ? "Current Default" : "" }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="['Id', 'LegacyId', 'Name', 'IsDefault']"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['Id', 'LegacyId', 'Name', 'IsDefault']"
        ></tr>
      </table>
      <table *ngIf="isEditMode" mat-table [dataSource]="assignedFacilities">
        <ng-container matColumnDef="Id">
          <th mat-header-cell *matHeaderCellDef><b>Id</b></th>
          <td mat-cell *matCellDef="let element">
            {{ element.Id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="LegacyId">
          <th mat-header-cell *matHeaderCellDef><b>Number</b></th>
          <td mat-cell *matCellDef="let element">
            {{ element.LegacyId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef><b>Name</b></th>
          <td mat-cell *matCellDef="let element">
            {{ element.Name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Assigned">
          <th mat-header-cell *matHeaderCellDef>
            <b>Is Assigned</b>
            <mat-checkbox
              [checked]="allAssigned"
              (change)="assignAllFacilities($event.checked)"
            ></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [checked]="element.Assigned"
              (change)="
                element.Assigned = $event.checked;
                updateAssignedFacility(element)
              "
            ></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="IsDefault">
          <th mat-header-cell *matHeaderCellDef><b>Is Default</b></th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [checked]="element.IsDefault"
              (change)="
                element.IsDefault = $event.checked;
                updateDefaultAssignedFacility(element)
              "
            ></mat-checkbox>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="['Id', 'LegacyId', 'Name', 'Assigned', 'IsDefault']"
        ></tr>
        <tr
          mat-row
          *matRowDef="
            let row;
            columns: ['Id', 'LegacyId', 'Name', 'Assigned', 'IsDefault']
          "
        ></tr>
      </table>
    </section>
  </panel-body>
</content-panel>
