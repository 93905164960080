import { FacilityAssignment } from './facility-assignment';

export class User {
  Id?: number;
  AzureOid?: string;
  FirstName?: string;
  LastName?: string;
  Email?: string;
  OfficeLocation?: string;
  FacilityAssignments?: FacilityAssignment[] = [];

  LastLogin?: Date;
  ModifiedOn?: Date;
  ModifiedBy?: string;
  CreatedOn?: Date;
  CreatedBy?: string;

  get DefaultLocation() {
    let defaultFacility = this.FacilityAssignments?.filter(
      (i) => i.IsDefault
    )[0];
    return defaultFacility
      ? defaultFacility.Facility.KeyChain.LegacyId
      : this.OfficeLocation;
  }

  constructor(user: any) {
    this.Id = user.Id;
    this.ModifiedBy = user.ModifiedBy;
    this.ModifiedOn = user.ModifiedOn;
    this.AzureOid = user.AzureOid;
    this.LastLogin = user.LastLogin;
    this.CreatedBy = user.CreatedBy;
    this.CreatedOn = user.CreatedOn;
    this.FirstName = user.FirstName;
    this.LastName = user.LastName;
    this.Email = user.Email;
    this.OfficeLocation = user.OfficeLocation;
    this.FacilityAssignments = user.FacilityAssignments
      ? user.FacilityAssignments
      : [];
  }
}

export class UserDataSource {
  Id?: number;
  FirstName?: string;
  LastName?: string;
  Email?: string;
  OfficeLocation?: string;
  DefaultLocation?: string;

  convertFromUser(user: User): UserDataSource {
    this.Id = user.Id;
    this.FirstName = user.FirstName;
    this.LastName = user.LastName;
    this.Email = user.Email;
    this.OfficeLocation = user.OfficeLocation;

    this.DefaultLocation = user.DefaultLocation;

    return this;
  }
}
