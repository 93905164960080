import { HttpClient } from '@angular/common/http';
import { Injectable, WritableSignal, signal } from '@angular/core';
import { EventType } from '@angular/router';
import { Observable } from 'rxjs';
import { WebSocketService } from 'src/app/shared/services/web-socket.service';

export type Profile = {
  displayName?: string;
  jobTitle?: string;
  mail?: string;
  officeLocation?: string;
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
};

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private readonly AZURE_GRAPHQL_URL: string =
    'https://graph.microsoft.com/v1.0/me';

  profile$: WritableSignal<Profile> = signal({});
  constructor(private http: HttpClient, private wsService: WebSocketService) {}

  getProfile(): Observable<Profile> {
    return this.http.get<Profile>(this.AZURE_GRAPHQL_URL);
  }

  selfProvisionUser(profile: Profile) {
    this.wsService.send({
      metadata: {
        eventType: 'CREATE',
        domain: 'UserAccount',
      },
      data: {
        firstName: profile.givenName,
        lastName: profile.surname,
        email: profile.userPrincipalName,
        azureOid: profile.id,
        officeLocation: profile.officeLocation,
      },
    });
  }
}
