<mat-form-field>
  <mat-chip-grid #chipGrid [formControl]="selectedValuesFormControl">
    @for (selected of selectedValues; track selected) {
    <mat-chip-row (removed)="remove(selected)">
      {{ selected.label }}
      <button matChipRemove [attr.aria-label]="'remove ' + selected">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    }
  </mat-chip-grid>
  <span class="material-symbols-outlined">arrow_drop_down</span>

  <mat-label>{{ control.label}}</mat-label>
  <input
    [formControl]="autoCompleteControl"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event)"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    @for (item of control.customOptions?.filteredOptions; track item.value) {
    <mat-option [value]="item">{{ item.label }}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
