import { KeyChain } from "./key-chain.model";

export class Facility {
  Id?: number;
  Name?: string;
  Street?: string;
  City?: string;
  State?: string;
  PostalCode?: string;
  POBox?: string;
  KeyChain?: KeyChain = new KeyChain();

  constructor(fac: any) {
    this.Id = fac.Id;
    this.Name = fac.Name;
    this.Street = fac.Street;
    this.City = fac.City;
    this.State = fac.State;
    this.PostalCode = fac.PostalCode;
    this.POBox = fac.POBox;
    this.KeyChain.LegacyId = fac.KeyChain?.LegacyId;
    this.KeyChain.SalesforceId = fac.KeyChain?.SalesforceId;
    this.KeyChain.OracleId = fac.KeyChain?.OracleId;
  }
}

export class AssignedFacilityDataSource {
  Id?: number;
  Name?: string;
  LegacyId?: string;
  Assigned?: boolean;
  IsDefault?: boolean;

  convertFromFacility(
    fac: Facility,
    assigned: boolean,
    isDefault: boolean
  ): AssignedFacilityDataSource {
    this.Id = fac.Id;
    this.Name = fac.Name;
    this.LegacyId = fac.KeyChain.LegacyId;
    this.Assigned = assigned;
    this.IsDefault = isDefault;
    return this;
  }
}
