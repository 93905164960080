import { Component } from '@angular/core';
import {
  PanelBodyComponent,
  PanelContainerComponent,
  PanelHeaderComponent,
} from 'src/app/shared/content-panels';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ControlType,
  DynamicForm,
  DynamicFormControlComponent,
  DynamicFormGroupComponent,
  FormBuilderService,
} from 'src/app/shared/forms';
import { RuleAction } from 'src/app/shared/forms/models/rule-action.enum';

@Component({
  selector: 'app-form-builder',
  standalone: true,
  imports: [
    PanelContainerComponent,
    PanelHeaderComponent,
    PanelBodyComponent,
    ReactiveFormsModule,
    DynamicFormControlComponent,
    DynamicFormGroupComponent,
  ],
  templateUrl: './form-builder.component.html',
  styleUrl: './form-builder.component.scss',
})
export class FormBuilderComponent {
  form: FormGroup;
  dynamicForm: DynamicForm;
  constructor(
    public formService: FormBuilderService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group([]);
    this.dynamicForm = this.formService.buildDynamicForm(this.formData);
    this.dynamicForm.bindControls(this.form);
    this.runRuleSet();

    this.form.valueChanges.subscribe((i) => {
      this.formService.resetValidations(
        this.dynamicForm.getControls(),
        this.dynamicForm.getGroups()
      );

      this.runRuleSet();
    });
  }

  private runRuleSet() {
    let controls = this.dynamicForm.getControls();
    let groups = this.dynamicForm.getGroups();
    let rules = this.formService.generateRuleSet(
      this.dynamicForm.rules,
      controls
    );

    rules.forEach((i) => {
      this.formService.applyRule(i, controls, groups);
    });

    controls
      .flatMap((i) => i.formControls)
      .forEach((i) =>
        i.formControl.updateValueAndValidity({
          onlySelf: true,
        })
      );
  }

  private formData = {
    name: 'Test Form',
    formGroups: [
      {
        type: 'GROUP',
        layoutDirection: 'row',
        children: [
          {
            type: 'CONTROL',
            propertyBinding: 'version',
            label: 'Version',
            defaultValue: '2',
            controlType: ControlType.PILL,
            customOptions: {
              selectOptions: [
                {
                  value: 2,
                  label: '2',
                },
                {
                  value: 3,
                  label: '3',
                },
              ],
            },
          },
        ],
      },
      {
        type: 'GROUP',
        layoutDirection: 'row',
        children: [
          {
            type: 'GROUP',
            label: 'THing 1',
            layoutDirection: 'column',
            customOptions: {
              metadata: {
                containerSize: 'full',
              },
            },
            children: [
              {
                type: 'CONTROL',
                propertyBinding: 'select',
                label: 'Version',
                defaultValue: '3',
                controlType: ControlType.MULTISELECT,
                customOptions: {
                  placeholder: 'This is thing',
                  selectOptions: [
                    {
                      value: 2,
                      label: '2',
                    },
                    {
                      value: 3,
                      label: '3',
                    },
                  ],
                },
              },
              {
                type: 'CONTROL',
                propertyBinding: 'text',
                label: 'Txt',
                defaultValue: 2,
                controlType: ControlType.INPUT,
                customOptions: {
                  placeholder: 'Text',
                },
              },
            ],
          },
          {
            type: 'GROUP',
            label: 'THing 2',
            customOptions: {
              metadata: {
                containerSize: 'full',
              },
            },
            layoutDirection: 'column',
            children: [
              {
                type: 'CONTROL',
                propertyBinding: 'select',
                label: 'Version',
                defaultValue: '3',
                controlType: ControlType.MULTISELECT,
                customOptions: {
                  placeholder: 'This is thing',
                  selectOptions: [
                    {
                      value: 2,
                      label: '2',
                    },
                    {
                      value: 3,
                      label: '3',
                    },
                  ],
                },
              },
              {
                type: 'CONTROL',
                propertyBinding: 'pill',
                label: 'Date',
                defaultValue: 2,
                controlType: ControlType.DATERANGE,
                customOptions: {
                  placeholder: 'Date here',
                },
              },
            ],
          },
        ],
      },
    ],
    rules: [
      {
        condition: "'{version}' === '3'",
        effects: [
          {
            action: RuleAction.MAKE_REQUIRED,
            applyTo: 'select',
            affectingType: 'control',
          },
        ],
      },
      {
        condition: "'{version}' === '3'",
        effects: [
          {
            action: RuleAction.MAKE_DISABLED,
            applyTo: 'pill',
            affectingType: 'control',
          },
        ],
      },
    ],
  };
}
