import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'tableColumn'
})
export class TableColumnPipe implements PipeTransform {
  transform(value: string): string {
    let extracted = '';
    if (!value) {
        return extracted;
    }
    let camel = value.match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g).join(' ');

    return camel;
  }
}
