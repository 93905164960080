import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavItem } from '../models/nav-item.model';
import { TokenClaimsService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  navItems = new BehaviorSubject<NavItem[]>(null);
  navItems$ = this.navItems.asObservable();

  constructor(private claimsService: TokenClaimsService) {}

  createNavBar() {
    let navLinks: NavItem[] = [];

    if (this.claimsService.canAccessEC) {
      let energy: NavItem = {
        Name: 'Energy Credits',
        Active: false,
        IconUrl: './assets/nav-icons/ec-icon-white.svg',
        Url: 'energy-credits',
      };
      navLinks.push(energy);
    }

    let eLearn: NavItem = {
      Name: 'eLearn',
      Active: false,
      IconUrl: './assets/nav-icons/el-icon.svg',
      Url: 'e-learn',
    };
    navLinks.push(eLearn);

    this.navItems.next(navLinks);
  }

  setActive(name: string) {
    let items = this.navItems.value;

    items?.forEach((i) => {
      if (i.Name === name) {
        i.Active = true;
      } else {
        i.Active = false;
      }
    });

    this.navItems.next(items);
  }
}
