import { Component } from '@angular/core';

@Component({
  selector: 'app-e-learn',
  standalone: true,
  imports: [],
  templateUrl: './e-learn.component.html',
  styleUrl: './e-learn.component.scss'
})
export class ELearnComponent {

}
