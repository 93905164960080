import { Component, Input } from '@angular/core';

@Component({
  selector: 'panel-header',
  standalone: true,
  imports: [],
  template: `
    <div class="container">
      <div class="heading">{{ heading }}</div>
      <div class="button-container">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
        overflow: hidden;
        border-radius: 5px 5px 0 0;
        height: 45px;

        .container {
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background: var(--panel-heading-background-color);
          color: var(--panel-heading-text-color);
          border-bottom: var(--content-border);

          .heading {
            padding-left: 10px;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          ::ng-deep > div > button {
            width: 45px;
            height: 45px;
            background: none;
            border-radius: 0px;
            border: none;
            font-size: 20px;
            color: var(--panel-heading-text-color);
            margin: 0;

            &:hover {
              background-color: var(--primary-button-hover-background-color);
              color: var(--primary-button-hover-text-color);
            }

            &:disabled {
              cursor: unset;
              color: var(--button-disabled-color);

              &:hover {
                background-color: rgba(0, 0, 0, 0);
              }
            }
          }
        }
      }
    `,
  ],
})
export class PanelHeaderComponent {
  @Input() heading: string = '';
}
