import { Component } from '@angular/core';

@Component({
  selector: 'page-content',
  standalone: true,
  template: `<div class="container"><ng-content></ng-content></div>`,
  styles: [
    `
      :host {
      }
      .container {
        box-shadow: inset 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
          inset 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
          inset 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        padding: 30px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        /* justify-content: flex-start; */
      }
    `,
  ],
})
export class PageContentComponent {}
