<div class="container" [ngClass]="nested ? 'no-border' : ''">
  @if(group.label) {
  <section class="row">
    <div class="header">{{ group.label }}</div>
    <ng-template></ng-template>
  </section>
  }
  <section [class]="group.layoutDirection">
    @for(group of nestedGroups; track group){ @if(!group.hidden) {
    <lib-dynamic-form-group
      [group]="group"
      [nested]="true"
      [attr.size]="
        group.customOptions.metadata?.containerSize
          ? group.customOptions.metadata?.containerSize
          : 'auto'
      "
      [ngClass]="
        group.customOptions.metadata?.showDivider || group.label
          ? 'border-left'
          : 'no-border'
      "
    ></lib-dynamic-form-group>
    } } @if(controls?.length > 0) {
    <section class="column">
      @for(control of controls; track control){ @if(!control.hidden) {
      <lib-dynamic-form-control
        [attr.controlType]="control.controlType"
        [control]="control"
      >
      </lib-dynamic-form-control>
      } }
    </section>
    }
  </section>
</div>
