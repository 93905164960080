import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { UrlBuilderService } from './url-builder.service';

let WSS_URL = '';

export interface Message {
  action?: string;
  data?: { metadata: MetaData; data: any };
}

export interface MetaData {
  eventType?: string;
  domain?: string;
  model?: string;
}

export class AlertActions {
  static SENDING = 'SENDING';
  static RECEIVED = 'RECEIVED';
  static PROCESSING = 'PROCESSING';
  static PROCESSED = 'PROCESSED';
  static PROCESSING_ERROR = 'PROCESSING_ERROR';
  static CONNECTION_ERROR = 'CONNECTION_ERRROR';
  static ERROR = 'ERROR';
  static ENTITY_CREATED = 'ENTITY_CREATED';
  static ENTITY_REMOVED = 'ENTITY_REMOVED';
  static ENTITY_UPDATED = 'ENTITY_UPDATED';
  static UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR';

  public static isError = (action: AlertActions) =>
    action === AlertActions.CONNECTION_ERROR ||
    action === AlertActions.PROCESSING_ERROR ||
    action === AlertActions.UNAUTHORIZED_ERROR;
}

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  connection$: WebSocketSubject<any>;
  RETRY_SECONDS = 10;

  constructor(urlBuilder: UrlBuilderService) {
    WSS_URL = urlBuilder.generateWssUrl();
  }

  connect(accessToken: string): Observable<any> {
    const url = WSS_URL + `?access_token=${accessToken}`;
    return of(url).pipe(
      switchMap((wsUrl) => {
        if (this.connection$) {
          return this.connection$;
        } else {
          this.connection$ = webSocket(wsUrl);
          return this.connection$;
        }
      })
      //retryWhen((errors) => errors.pipe(delay(this.RETRY_SECONDS)))
    );
  }

  send(data: any) {
    if (this.connection$) {
      let event = {
        action: 'send',
        data: data,
      };

      this.connection$.next(event);
    } else {
      console.error('Did not send data, open a connection first');
    }
  }

  closeConnection() {
    if (this.connection$) {
      this.connection$.complete();
      this.connection$ = null;
    }
  }
  ngOnDestroy() {
    this.closeConnection();
  }
}
