import gql from 'graphql-tag';

export const GET_USER_BY_ID = gql`
  query GetUserById($id: String) {
    GetUser(Id: $Id) {
      Id
      LastName
      FirstName
      Email
      OfficeLocation
      FacilityAssignments {
        IsDefault
        Facility {
          Name
          Id
          KeyChain {
            LegacyId
            OracleId
            SalesforceId
          }
        }
      }
      LastLogin
      CreatedBy
      CreatedOn
    }
  }
`;
export const GET_USER_BY_AZURE_ID = gql`
  query GetUserByAzureId($id: String) {
    GetUserByAzureId(Id: $Id) {
      AzureOid
      CreatedBy
      CreatedOn
      FirstName
      Id
      LastLogin
      LastName
      ModifiedBy
      ModifiedOn
    }
  }
`;
export const GET_USER_PREFERENCE_BY_AZURE_ID = gql`
  query GetUserPreferenceByAzureId($id: String) {
    GetUserPreferenceByAzureId(Id: $id) {
      AzureOid
      Value
    }
  }
`;
export const GET_ALL_USERS = gql`
  query GetAllUsers($filter: String) {
    GetAllFacilities {
      Id
      KeyChain {
        LegacyId
        OracleId
        SalesforceId
      }
      Name
      IsActive
    }
    GetAllUsers(filter: $filter) {
      Items {
        Id
        LastName
        FirstName
        Email
        OfficeLocation
        FacilityAssignments {
          IsDefault
          Facility {
            Name
            Id
            KeyChain {
              LegacyId
              OracleId
              SalesforceId
            }
          }
        }
        LastLogin
        CreatedBy
        CreatedOn
      }
      HasNextPage
      HasPreviousPage
      PageNumber
      PageSize
      TotalCount
      TotalPages
      SortDirection
    }
  }
`;
