import { FormGroup, Validators } from '@angular/forms';
import { DynamicFormGroup } from './dynamic-form-group.model';
import { FormRule } from './form-rule.model';
import { DynamicFormControl } from './dynamic-form-control.model';

export class DynamicForm {
  id?: number;
  name?: string;
  formGroups?: DynamicFormGroup[];
  rules?: FormRule[];

  getGroups(): DynamicFormGroup[] {
    return this.formGroups
      ?.flatMap((i) => [i, i.getNestedGroups()])
      .flatMap((i) => i);
  }

  getControls(): DynamicFormControl[] {
    return this.formGroups?.flatMap((i) => i.getControls());
  }

  /**
   * Adds a group to the form.
   * @param group DynamicFormGroup to add to the DynamicForm.
   */
  addGroup(group: DynamicFormGroup): void {
    this.formGroups = [...this.formGroups, group];
  }

  /**
   * Binds all controls within the DynamicForm to the provided angular FormGroup.
   * @param formGroup The angular form group to bind controls to.
   */
  bindControls(formGroup: FormGroup): void {
    this.formGroups
      .flatMap((i) => i.getControls().flatMap((i) => i.formControls))
      .forEach((i) => formGroup.addControl(i.propertyBinding, i.formControl));

    // Bind default validations
    this.getControls()
      .filter((i) => i.customOptions.isRequired)
      .flatMap((i) => i.formControls)
      .forEach((i) => i.formControl.addValidators(Validators.required));

    this.getControls()
      .filter((i) => i.customOptions.isDisabled)
      .flatMap((i) => i.formControls)
      .forEach((i) => i.formControl.disable({ onlySelf: true }));
  }
}
