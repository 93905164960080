<mat-drawer-container>
  <mat-drawer
    class="settings-drawer"
    mode="over"
    position="end"
    #drawer
    (closedStart)="updateDrawerState()"
    [opened]="drawerService.state()"
  >
    <div class="drawer-header">
      <h2>{{ drawerService.heading }}</h2>
    </div>
    <div class="drawer-body">
      @if(drawerService.heading === 'Settings'){
      <lib-user-settings></lib-user-settings>
      } @else if(drawerService.heading === 'Filter(s)') {
      <filter-drawer></filter-drawer>
      }
    </div>
  </mat-drawer>
  <mat-drawer-content>
    @if (spinnerService.spinnerCounter$ | async) {
    <div id="spinner-container">
      <mat-spinner
        diameter="60"
        mode="indeterminate"
        strokeWidth="4"
        color="accent"
      ></mat-spinner>
    </div>
    }
    <nav-bar>
      <page-header></page-header>
      <page-content>
        <router-outlet></router-outlet>
      </page-content>
    </nav-bar>
  </mat-drawer-content>
</mat-drawer-container>
